document.addEventListener("turbolinks:load", () => {

  if ($('#lease_info').children().length > 1) {
    $('#btn_add_lease_info').addClass('d-none');
  }

  $('#lease_info').on('cocoon:after-insert', function(e, insertedItem) {
    $('#btn_add_lease_info').addClass('d-none');
    add_masks();
    add_select2();
    add_money_masks();
  });

  $('#lease_info').on('cocoon:after-remove', function(e, removedItem) {
    $('#btn_add_lease_info').removeClass('d-none');
  });
});
