
document.addEventListener("turbolinks:load", () => {

  $("input[name='lessor[person_type]']").change(function() {
    toggle_person_type_fields($(this).val());
  });
  toggle_person_type_fields($("input[name='lessor[person_type]']:checked").val());
});

window.toggle_person_type_fields = function(person_type) {
  if (person_type == 'physical') {

    $("label[for='lessor_name']").html('Nome completo <abbr title="obrigatório">*</abbr>');
    $("label[for='lessor_document']").html('CPF <abbr title="obrigatório">*</abbr>');
    $('#lessor_document').mask("999.999.999-99");

  } else {

    $("label[for='lessor_name']").html('Razão social <abbr title="obrigatório">*</abbr>');
    $("label[for='lessor_document']").html('CNPJ <abbr title="obrigatório">*</abbr>');
    $('#lessor_document').mask("99.999.999/9999-99");
  }
}
